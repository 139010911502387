import React, {useRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import './Receipt.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {PaymentType} from "../context/WebSocketContext";

export function mapPaymentMethod(paymentMethod: PaymentType | undefined): string {
    switch (paymentMethod) {
        case PaymentType.CARD:
            return 'Card Payment';
        case PaymentType.PAY_BY_TRANSFER:
            return 'Pay By Transfer';
        case PaymentType.PAY_ATTITUDE:
            return 'Pay By Phone';
        default:
            return 'Card Payment';
    }
}

function getCurrentDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

const Receipt = ({
                     open,
                     onClose,
                     paymentMethod,
                     date,
                     price,
                     currency,
                     receiptNumber,
                     cardNumber,
                     expiry,
                     accountNumber,
                     bankName,
                     phoneNumber,
                     beneficiaryAccountNumber,
                     beneficiaryAccountName,
                     originatingAccountNumber,
                     originatingAccountName,
                     originatingBankName,
                     transactionDate,
                     status,
                     referenceID,
                 }: {
    open: boolean;
    onClose: () => void;
    paymentMethod: PaymentType | undefined;
    date: Date;
    price: string;
    currency: string;
    receiptNumber: string;
    cardNumber?: string;
    expiry?: string;
    accountNumber?: string;
    bankName?: string;
    phoneNumber?: string;
    beneficiaryAccountNumber?: string;
    beneficiaryAccountName?: string;
    originatingAccountNumber?: string;
    originatingAccountName?: string;
    originatingBankName?: string;
    transactionDate?: string;
    status?: string;
    referenceID?: string;
}) => {
    const receipt = {
        receiptNumber: receiptNumber,
        merchantName: "Ziroopay",
        displayDateTime: getCurrentDateTime(date),
        purchaseProducts: [
            {name: "Items from Original Order", price: `${currency}${price}`, totalPrice: `${currency}${price}`},
        ],
        sum: `${currency}${price}`,
        //totalVat: "$2.50",
        merchantAddress: "1 Chief Hope Harriman St, Lekki Phase 1, Lagos 105102",
        phone: "+234 816 430 1239"
    };

    const receiptRef = useRef<HTMLDivElement>(null);
    const downloadPDF = async () => {
        if (receiptRef.current) {
            // Capture the receipt content as a canvas
            const canvas = await html2canvas(receiptRef.current, {scale: 2});
            const imgData = canvas.toDataURL('image/png');

            // Create PDF and add the receipt image
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const contentWidth = pageWidth * 0.5; // Adjust scaling here
            const contentHeight = (canvas.height * contentWidth) / canvas.width;

            const offsetX = (pageWidth - contentWidth) / 2;
            const offsetY = (pageHeight - contentHeight) / 3;

            pdf.setFillColor(255, 255, 255); // White background
            pdf.rect(offsetX - 5, offsetY - 5, contentWidth + 10, contentHeight + 10, 'F'); // Background rectangle with padding
            pdf.setDrawColor(0, 0, 0); // Black outline
            pdf.setLineDashPattern([3, 2], 0);
            pdf.rect(offsetX - 5, offsetY - 5, contentWidth + 10, contentHeight + 10); // Border rectangle

            pdf.addImage(imgData, 'PNG', offsetX, offsetY, contentWidth, contentHeight);
            pdf.save(`receipt_${receiptNumber}.pdf`);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent className="dialog-root">
                <div ref={receiptRef} className="receipt-content">
                    <div className="logo-container">
                        <img src="/logo.png" alt="Logo" className="receipt-logo"/>
                    </div>
                    <div className="receipt-content">
                        <div className="header">ZIROOPAY RECEIPT</div>
                        {/* <div className="receipt-number">Transaction ID: {receipt.receiptNumber}</div> */}
                        <div className="company-date">
                            <div className="company-wrapper">
                                <span className="company-title">{receipt.merchantName}</span>
                            </div>
                            <span className="date">{receipt.displayDateTime}</span>
                        </div>

                        <div className="separator"></div>
                        <div className="payment-confirmation">
                            The Merchant received your payment of
                            <span className="payment-amount">{currency}{formatAmount(price)}</span>
                        </div>
                        {paymentMethod === PaymentType.CARD && (
                            <div className="card-details">
                                <h3>{mapPaymentMethod(paymentMethod)}</h3>
                                <p>Card Number: {maskCardNumber(cardNumber)}</p>
                                <p>Transaction ID: {receipt.receiptNumber}</p>
                            </div>
                        )}
                        {paymentMethod === PaymentType.PAY_ATTITUDE && (
                            <div className="card-details">
                                <h3>{mapPaymentMethod(paymentMethod)}</h3>
                                <p>Phone Number: {phoneNumber}</p>
                                <p>Transaction ID: {receipt.receiptNumber}</p>
                            </div>
                        )}
                        {paymentMethod === PaymentType.PAY_BY_TRANSFER && (
                            <div className="bank-transfer-details">
                                <h3>{mapPaymentMethod(paymentMethod)}</h3>
                                <p>Beneficiary Account: {beneficiaryAccountNumber} - {beneficiaryAccountName}</p>
                                <p>Originating Account: {originatingAccountNumber} - {originatingAccountName}</p>
                                <p>Bank Name: {originatingBankName}</p>
                                <p>Transaction Date: {transactionDate}</p>
                                <p>Status: {status}</p>
                                <p>Reference ID: {referenceID}</p>
                                <p>Transaction ID: {receipt.receiptNumber}</p>
                            </div>
                        )}
                        <div className="thank-you">THANK YOU!</div>
                        <p className="powered-by">Powered by ZirooPay</p>
                    </div>
                </div>
                <button onClick={downloadPDF} className="download-button">Download Receipt as PDF</button>
            </DialogContent>
        </Dialog>
    );
};

export default Receipt;

export function formatAmount(amount: string): string {
    const numericAmount = parseFloat(amount);
    if (isNaN(numericAmount)) {
        return amount; // Return original string if parsing fails
    }
    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(numericAmount);
}

export function maskCardNumber(cardNumber: string | undefined): string {
    if (!cardNumber) return '';
    // Ensure card number is valid and of sufficient length
    if (cardNumber.length < 10) return cardNumber; // Return as is if too short
    const firstSix = cardNumber.slice(0, 6);
    const lastFour = cardNumber.slice(-4);
    const masked = `${firstSix}${'*'.repeat(cardNumber.length - 10)}${lastFour}`;
    return masked;
}

