import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from 'react';
import {setToLocalStorage} from "../localStorageUtils";
import {useNavigate} from "react-router-dom";

interface WebSocketContextProps {
    sendMessage: (message: object) => void;
    setId: (id: string) => void; // Function to update the ID dynamically
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export enum PaymentType {
    CARD = 'Card',
    PAY_ATTITUDE = 'PayAttitude',
    PAY_BY_TRANSFER = 'PayByTransfer',
}

export interface PayAttitudeDetails {
    phoneNumber: string,
}

export interface CardPayloadDetails {
    cardNumber: string,
    expiry: string
}

export interface PayByTransferDetails {
    beneficiaryAccountNumber: string;
    beneficiaryAccountName: string;
    transactionDate: string;
    originatingBankName: string;
    originatingAccountName: string;
    originatingAccountNumber: string;
    sessionId: string;
    referenceID: string;
}

export interface Message {
    type: PaymentType,
    webPurchaseId: string,
    status: string,
    amount: string,
    payAttitude?: PayAttitudeDetails,
    card?: CardPayloadDetails,
    payByTransfer?: PayByTransferDetails
}

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [id, setId] = useState<string | null>(null);
    const webSocketRef = useRef<WebSocket | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) return;

        const connectWebSocket = () => {
            const ws = new WebSocket(
                `${process.env.REACT_APP_API_BASE_URL!.replace(/^http/, 'ws')}/api/payment/socket/${id}`
            );
            webSocketRef.current = ws;

            ws.onopen = () => {
                console.log("WebSocket connection established");
            };

            ws.onmessage = (event) => {
                console.log("WebSocket message received:", event.data);
                const message = JSON.parse(event.data);
                handleWebSocketMessage(message);
            };

            ws.onclose = (event) => {
                console.warn("WebSocket connection closed. Reconnecting...", event);
                setTimeout(connectWebSocket, 5000); // Reconnect after a delay
            };

            ws.onerror = (error) => {
                console.error("WebSocket error:", error);
                ws.close(); // Close and trigger reconnect
            };
        };

        connectWebSocket();

        return () => {
            webSocketRef.current?.close(); // Clean up WebSocket on unmount or ID change
        };
    }, [id]);

    const sendMessage = (message: object) => {
        if (webSocketRef.current && webSocketRef.current?.readyState === WebSocket.OPEN) {
            webSocketRef.current?.send(JSON.stringify(message));
        } else {
            console.warn("WebSocket is not connected. Message not sent:", message);
        }
    };

    const handleWebSocketMessage = (message: Message) => {
        if (message.status.toLowerCase() === "approved") {
            navigate('success', {
                state: { message }
            })
        } else if (message.status.toLowerCase() === "declined") {
            navigate('/failure', {
                state: { id },
            });
        }
    };

    return (
        <WebSocketContext.Provider value={{sendMessage, setId}}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = (): WebSocketContextProps => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error("useWebSocket must be used within a WebSocketProvider");
    }
    return context;
};
