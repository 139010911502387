import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from "../components/LoadingSpinner";
import {Message, useWebSocket} from "../context/WebSocketContext";

const SecureCheckPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { setId } = useWebSocket();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (id) {
            setId(id); // Set the WebSocket connection to the current ID
        }
    }, [id, navigate, setId]);

    return (
        <div className="secure-check-page">
            {isLoading ? <LoadingSpinner /> : <p>Waiting for payment status...</p>}
        </div>
    );
};

export default SecureCheckPage;
